import React, { useState, useEffect } from "react";
import Link from "next/link";

import Logo from "./Logo";
import CmsMenuDropdown from "./CmsMenuDropdown";
import { NavLink } from "./NavLink";
import Hamburger from "./Hamburger";

import AnchorLink from 'react-anchor-link-smooth-scroll'

function NavBarMobile({ navigation }) {
  const [show, setShow] = useState(true);
  const [isTop, setIsTop] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  
  useEffect(() => {

    const controlNavbar = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY > lastScrollY) {
          setShow(false);
          document.body.classList.remove('mobileMenuShow');
        } else {
          setShow(true);
          document.body.classList.add('mobileMenuShow');
        }
        setLastScrollY(window.scrollY);
  
        if (window.scrollY > 0) {
          setIsTop(false);
          document.body.classList.remove('isTop');
        } else {
          setIsTop(true);
          document.body.classList.add('isTop');
        }
      }
    };
    
    if (typeof window !== "undefined") {
      window.addEventListener("load", controlNavbar);
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }

  }, [lastScrollY]);
  return (
    <>
      <nav
        className={`mainNavMobile ${!show ? "navHidden" : ""} ${
          !isTop ? "floating" : ""
        }`}
      >
          <div className="flex h-full w-full justify-between items-center">
            <Link href="/">
                <a className="Logo">
                    <Logo />
                </a>
            </Link>
          </div>
      </nav>
      {/* <Hamburger /> */}
      <div className="mobileCanvasOverlay"></div>
      <div className="mobileOffCanvas">
        <div className="mobileMenu">
          <div className="mobileMenuContainer">
            <div>
              <ul className={`navBar`}>
                {navigation.map(function (link, i) {
                    return (
                      <li key={i}>
                        <NavLink link={link} exact />
                      </li>
                    )
                })}
                <li>
                  <AnchorLink href="#contattaci" onClick={() => {
                      document.body.classList.remove('mobileMenuOpened')
                    }}
                  >
                      Contattaci
                  </AnchorLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavBarMobile;
