import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { NavLink } from './NavLink'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline'

function CmsMenuDropdown({ link }) {

    let [isOverButton, setIsOverButton] = useState(false);
    let [isOverList, setIsOverList] = useState(false);
    let [isOpen, setIsOpen] = useState();
    let [isTouchInput, setIsTouchInput] = useState();
    let [hasClicked, setHasClicked] = useState();
    let button = useRef(null);

    useEffect(() => {
        if (isOpen && !isOverButton && !isOverList && !isTouchInput) {
            button.current.click();
            setIsOpen(false);
        } else if (!isOpen && (isOverButton || isOverList) && !isTouchInput) {
            button.current.click();
            setIsOpen(true);
        }
        
        setIsTouchInput(false);
        setHasClicked(false);
    }, [hasClicked, isOverButton, isOverList, isOpen, isTouchInput]);

    return (
        <div className="relative">
            <button 
            className={`${isOpen ? 'isOpen' : ''}`}
            ref={button}
            onTouchStart={() => {
              setIsTouchInput(true);
            }}
            onMouseEnter={event => {
              setIsOverButton(true);
            }}
            onMouseLeave={event => {
              setIsOverButton(false);
            }}
            onClick={() => {
              setHasClicked(true);
              setIsOpen(!isOpen);
            }}
            onKeyDown={() => {
              setIsOpen(!isOpen);
            }}
            type="button">
                {link.name} 
                <ChevronDownIcon className='dropdown-icon-down' />
            </button>

            <div className='dropdown-panel'>
                <div className={`dropdown-panel-inner columns-${link.columns} ${isOpen ? 'block' : 'hidden'}`}
                    onMouseEnter={event => {
                        setIsOverList(true);
                    }}
                    onMouseLeave={event => {
                        setIsOverList(false);
                    }}
                >
                    {link.navigation.map(function(link, i) {
                        return (
                            <NavLink link={link} key={i} exact />
                        )
                    })}
                </div>
            </div>
        </div>     
    )
}

export default CmsMenuDropdown