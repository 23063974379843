import dynamic from "next/dynamic";

import ContactButtons from './ContactButtons'

const dynamicComponents = {
    slideshow: dynamic(() => import("./CmsSlideshow")),
    text: dynamic(() => import("./CmsText")),
    image: dynamic(() => import("./CmsImage")),
    card: dynamic(() => import("./CmsCard")),
    cardGroup: dynamic(() => import("./CmsCardGroup")),
    swiper: dynamic(() => import("./CmsSwiper")),
};

function CmsRow({ row }) {
    return (
        <section id={`${row.id ?? ''}`}>
            <div className={ row.full ? 'container-xxl mx-auto' : `container xl:max-w-6xl mx-auto`}>
                <div 
                className={row.columns != 'full' && row.columns > 1 ? `grid lg:grid-cols-${row.columns}` : ''}>
                    {row.components.map(function (component,i) {
                        component.name = component.name == 'card-group' ? 'cardGroup' : component.name;
                        const Component = dynamicComponents[component.name];
                        component.options.columns = typeof component.options.columns != 'undefined' ? component.options.columns : 1;

                        if(typeof Component != 'undefined') {
                            return (
                                <Component component={component}  key={`component_${i}`} />
                            )
                        }
                    })}
                </div>
            </div>
            {row.id == 'contattaci' &&
                <ContactButtons />
            }
        </section>
    )
}

export default CmsRow