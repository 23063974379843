import React, { useState, useEffect } from "react";

import styles from './Hamburger.module.scss'

function Hamburger({}) {
    const [isActive, setIsActive] = useState(0);

    function toogleBurger(e) {
        setIsActive(!isActive)
    }

    useEffect(() => {
        if(isActive) {
            document.body.classList.add('mobileMenuOpened');
        } else {
            document.body.classList.remove('mobileMenuOpened');
        }
    }, [isActive])

  return (
    <button onClick={toogleBurger} className={`btn_menu ${isActive ? 'menu_open' : ''} burger`} type="button">
        <i className={`btn_menu__bars`} aria-hidden="true"></i>
    </button>
  );
}

export default Hamburger;
