import React from "react";
import { TailSpin } from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function ContactButtons() {
  const [showModal, setShowModal] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [showResult, setShowResult] = React.useState(0);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      name: event.target.name.value,
      email: event.target.email.value,
      subject: process.env.BASE_URL + " - " + event.target.subject.value,
      message: event.target.message.value,
    };

    setShowLoading(true);

    // const response = await sendMail(data);
    fetch(process.env.BASE_URL + "/api/sendmail", {
      body: JSON.stringify(data),
      method: "POST",
    })
    .then((res) => res.json())
    .then((data) => {
      setShowLoading(false);
      if(data.response == 1) {
        setShowResult(1);
      } else {
        setShowResult(1);
      }
    })
    .catch((error) => {
      setShowLoading(false);
      setShowResult(2);
    });
  };

  const closeForm = () => {
    document.querySelector("body, html").classList.remove("no-scroll");
    setShowModal(false);
    setShowResult(0);
  };

  return (
    <>
      <div data-aos="fade-up">
        <p className="flex flex-wrap justify-center mt-8 text-center">
          <a
            href="#"
            className="btn btn-big mx-12 my-8 w-52 md:w-auto"
            onClick={(e) => {
              e.preventDefault();
              document.querySelector("body, html").classList.add("no-scroll");
              setShowModal(true);
            }}
          >
            contattaci
          </a>
          <a href={`tel:${process.env.PHONE_NUMBER}`} className="btn btn-big btn-light mx-12 my-8 w-52 md:w-auto">
            chiamaci
          </a>
        </p>
      </div>
      {showModal ? (
        <>
          <div className="modal-cms">
            <div className="modal-cms-container relative">
              {showResult == 0 ? (
                <>
                  {showLoading ? (
                    <div className="absolute w-full h-full bg-white z-10 flex flex-col justify-center items-center">
                      <TailSpin
                        type="Puff"
                        color="#09c2f1"
                        height={70}
                        width={70}
                        timeout={3000}
                      />
                      <div>Invio in corso...</div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/*content*/}
                  <form className="w-full" onSubmit={handleSubmit}>
                    <div className="modal-cms-container-inner">
                      {/*body*/}
                      <div className="relative p-6 flex-auto">
                        <div className="flex justify-end mb-8">
                          <button
                            className="closeBtn"
                            onClick={(e) => {
                              e.preventDefault();
                              closeForm();
                            }}
                          >
                            <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                              ×
                            </span>
                          </button>
                        </div>
                        <div className="modal-form-row">
                          <label
                            className="theme-c2 font-bold"
                            htmlFor="form_name"
                          >
                            Nome <span className="required">*</span>
                          </label>
                          <input
                            id="form_name"
                            className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                            type="text"
                            placeholder=""
                            aria-label="Nome (obbligatorio)"
                            name="name"
                            required
                          />
                        </div>
                        <div className="modal-form-row">
                          <label
                            className="theme-c2 font-bold"
                            htmlFor="form_email"
                          >
                            Email <span className="required">*</span>
                          </label>
                          <input
                            id="form_email"
                            className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                            type="email"
                            placeholder=""
                            aria-label="Email (obbligatorio)"
                            name="email"
                            required
                          />
                        </div>
                        <div className="modal-form-row">
                          <label
                            className="theme-c2 font-bold"
                            htmlFor="form_subject"
                          >
                            Oggetto
                          </label>
                          <input
                            id="form_subject"
                            className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                            type="text"
                            placeholder=""
                            aria-label="Oggetto"
                            name="subject"
                          />
                        </div>
                        <div className="modal-form-row">
                          <label
                            className="theme-c2 font-bold"
                            htmlFor="form_message"
                          >
                            Messaggio
                          </label>
                          <textarea
                            id="form_message"
                            className="appearance-none bg-transparent border-none w-full h-32 text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                            type="text"
                            placeholder=""
                            aria-label="Messaggio"
                            name="message"
                          ></textarea>
                        </div>
                        <div className="flex items-center justify-center relative">
                          <input
                            className="mr-4 cursor-pointer"
                            id="form_privacy"
                            type="checkbox"
                            aria-label="Privacy"
                            name="privacy"
                            required
                          />
                          <label 
                            className="cursor-pointer font-light text-xs"
                            htmlFor="form_message"
                          >
                            Acconsento al trattamento dei miei dati secondo <a className="theme-c2" target="_blank" href="/privacy">l&apos; informativa sulla privacy</a>
                          </label>
                        </div>
                      </div>
                      {/*footer*/}
                      <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                        <button className="sendBtn" type="submit">
                          Invia messaggio
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <div className="modal-cms-container-inner">
                    <div className="relative p-6 flex-auto">
                      <div className="flex justify-end mb-8">
                        <button
                          className="closeBtn"
                          onClick={(e) => {
                            e.preventDefault();
                            closeForm();
                          }}
                        >
                          <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                          </span>
                        </button>
                      </div>
                      <div className="bg-white z-10 flex flex-col justify-center items-center">
                        {showResult == 1 ? (
                          <>
                            <h3 className="text-xl theme-c2 font-bold text-center">
                              Grazie per averci contattato!
                            </h3>
                            <p className="font-normal mt-8 text-center">
                              Risponderemo alla tua richiesta quanto prima.
                            </p>
                            <div className="mt-8 mb-12">
                              <button
                                className="sendBtn"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  closeForm();
                                }}
                              >
                                Chiudi
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <h3 className="text-xl text-red-600 font-bold text-center">
                              Si è verificato un errore!
                            </h3>
                            <p className="font-normal mt-8 text-center">
                              Ci scusiamo per il disguido.<br /> 
                              Ti preghiamo di contattarci scrivendo a <a href="mailto:info@procne.it" className="theme-c2">info@procne.it</a>
                            </p>
                            <div className="mt-8 mb-12">
                              <button
                                className="sendBtn"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  closeForm();
                                }}
                              >
                                Chiudi
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default ContactButtons;
