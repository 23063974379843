import { useRouter } from "next/router";
import Link from "next/link";
import PropTypes from "prop-types";
import CmsMenuDropdown from "./CmsMenuDropdown";

NavLink.propTypes = {
  // href: PropTypes.string.isRequired,
  // exact: PropTypes.bool
};

NavLink.defaultProps = {
  exact: false,
};

export { NavLink };

function NavLink({ link, exact }) {
  const { asPath } = useRouter();
  const isActive = exact
    ? asPath === link.path
    : asPath.startsWith(link.path);
    
  if (typeof link != "undefined") {

    if (typeof link.navigation == "undefined" || link.navigation.length == 0) {
      return (
        <Link href={link.path != "" ? link.path : "#"}>
          <a className={`${isActive ? "active" : ""}`} onClick={() => {
            document.body.classList.remove('mobileMenuOpened')
          }} >{link.name}</a>
        </Link>
      );
    } else {
      return <CmsMenuDropdown link={link} />;
    }
  } else {
      console.log(link);
      return (<></>)
  }
}
