import React from "react";

export default function MiniLogo({ size }) {
  return (
    <>
        <svg viewBox="0 0 64.499998 82.499998"
        height="110"
        width="86" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M 0,51.46484 25.42187,41.32813 0,31.10938 V 22.13672 L 35.30469,36.25781 V 46.55859 L 0,60.4375 Z m 0,0"
                    id="path988" />
                <path
                    d="m 55.66406,0 c 5.48047,12.79297 8.64063,27.41406 8.64063,41.28906 0,13.8711 -3.16016,28.49219 -8.64063,41.28516 l -8.88672,-2.91016 c 3.32031,-10.70703 7.3086,-22.00781 7.3086,-38.375 0,-16.36718 -3.98829,-27.66406 -7.3086,-38.38672 L 55.66406,0"
                    id="path990" />
        </svg>
    </>
  )
}