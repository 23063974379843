import React, { useState, useEffect } from "react";

import AnchorLink from 'react-anchor-link-smooth-scroll';

function CmsTopArrow({ target }) {
  const [show, setShow] = useState(true);

  useEffect(() => {

    const controlNavbar = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY > 0) {
            setShow(true);
        } else {
            setShow(false);
        }
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("load", controlNavbar);
      window.addEventListener("scroll", controlNavbar);
      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [show]);

  return (
    <AnchorLink href={`#${target}`}
      className={`back-to-top cursor-pointer fixed ${show ? 'flex' : 'hidden'} bottom-8 right-8 left-auto z-[999] h-10 w-10 items-center justify-center transition duration-300 ease-in-out`}
    >
      <span className="mt-[6px] h-3 w-3 rotate-45 border-t border-l"></span>
    </AnchorLink>
  );
}

export default CmsTopArrow;
