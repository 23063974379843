import React, { useState, useEffect } from "react"
import Head from 'next/head'
import CmsRow from './CmsRow'
import CmsFooter from './CmsFooter'
import CmsTopArrow from "./CmsTopArrow"

import Aos from 'aos';
import 'aos/dist/aos.css';

function CmsPage({ structure }) {

    useEffect(() => {
    
        if (document.readyState !== 'loading') {
    
          /* HOMEPAGE */
          applyAosToElement('.home #riga1 .swiper-slide .caption', 'fade-right');
          applyAosToElement('.home #riga1 .swiper-slide .img-container', 'fade-left');
          applyAosToElement('.home #riga2 .text', 'fade-up');
          applyAosToElement('.home #riga4 .text', 'fade-right');
          applyAosToElement('.home #riga4 .image', 'fade-left');
          applyAosToElement('.home #riga5 .text', 'fade-left');
          applyAosToElement('.home #riga5 .image', 'fade-right');
          applyAosToElement('.home #riga6 h2', 'fade-up');
          applyAosToElement('.home #diconodinoi', 'fade-up');
          applyAosToElement('#swiper-1654608810', 'fade-up');
          applyAosToElement('.home #riga8', 'fade-up');
          applyAosToElementByIndex('.home #riga9 .text', 0, 'fade-right');
          applyAosToElementByIndex('.home #riga9 .text', 1, 'fade-up');
          applyAosToElementByIndex('.home #riga9 .text', 2, 'fade-left');
          applyAosToElementByIndex('.home #contattaci .text', 0, 'fade-up');
          applyAosToElementByIndex('.home #contattaci .text p', 0, 'fade-up');
            
          Aos.init();
        } 
    
      }, []);


    // const iubendaImplementation = `
    // <script type="text/javascript">
    // var _iub = _iub || [];
    // _iub.csConfiguration = {"consentOnContinuedBrowsing":false,"floatingPreferencesButtonDisplay":"bottom-left","invalidateConsentWithoutLog":true,"perPurposeConsent":true,"siteId":2793635,"whitelabel":false,"cookiePolicyId":97699674,"lang":"it","cookiePolicyUrl":"https://www.procne.it/privacy", "banner":{ "acceptButtonColor":"#09C2F1","acceptButtonDisplay":true,"backgroundColor":"#25272F","closeButtonRejects":true,"customizeButtonCaptionColor":"#25272F","customizeButtonColor":"#C7CAD7","customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"float-bottom-center" }};
    // </script>
    // <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>
    // `;

    const analyticsImplementation = `
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-5950SL1VZG"></script>
    <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-5950SL1VZG');
    </script>
    `;

    const googleConsentMode = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag("consent","default",{
    ad_personalization: "denied",
    ad_storage: "denied",
    ad_user_data: "denied",
    analytics_storage: "denied",
    functionality_storage: "denied",
    personalization_storage: "denied",
    security_storage: "granted",
    wait_for_update: 500,
    });
    gtag("set", "ads_data_redaction", true);
    gtag("set", "url_passthrough", false);
    `;

    const gTagManagerScript = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-W6T2H9RM');`

    return (
        <>
            <Head>
                <title>{ structure.page.title }</title>
                <meta name="description" content={ structure.page.description } />
                <script data-cookieconsent="ignore" dangerouslySetInnerHTML={{ __html: googleConsentMode }}></script>
                <script data-cookieconsent="ignore" dangerouslySetInnerHTML={{ __html: gTagManagerScript }}></script>
            </Head>
            <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W6T2H9RM" height="0" width="0" style={{display:'none', visibility: 'hidden'}}></iframe></noscript>
            <div dangerouslySetInnerHTML={{ __html: analyticsImplementation }}></div>
            <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="021ba0c4-de8a-4e78-80d3-af136585ab75" data-blockingmode="auto" type="text/javascript" async></script>
            <main className={`${structure.page.template} cmsPage`} id={`${structure.page.id ?? ''}`}>
                
                {structure.rows.map(function(row, i) {
                    return <CmsRow row={row} key={i} />
                })}
            </main>
            <CmsFooter structure={structure} />
            <CmsTopArrow target={structure.page.id}/>
        </>
    )
}


function applyAosToElement(selector, effect) {
    if(document.querySelector(selector)) {
        document.querySelector(selector).setAttribute('data-aos', effect);
    }
}
  
function applyAosToElementByIndex(selector, index, effect) {
    if(document.querySelectorAll(selector)[index]) {
        document.querySelectorAll(selector)[index].setAttribute('data-aos', effect);
    }
}

export default CmsPage
