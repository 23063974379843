import React from "react";

export default function Logo({ size }) {
  return (
    <>
    {(typeof size  == "undefined" || size == 'lg') &&
        <svg width="156" height="31" viewBox="0 0 156 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M101.089 22.6836H118.414V19.8093H101.089V22.6836ZM101.089 11.1878H118.414V8.31204H101.089V11.1878ZM101.089 16.9364H118.414V14.0621H101.089V16.9364ZM80.8714 22.6836H83.7604V11.1878H95.3093V22.6836H98.1998V8.31204H80.8714V22.6836ZM60.6524 22.6836H77.9779V19.8093H63.54V11.1878H77.9779V8.31204H60.6524V22.6836ZM43.3196 11.1878H54.8758V19.8078H43.3196V11.1878ZM40.4379 22.6836H57.7634V8.31204H40.4379V22.6836ZM20.2175 22.6836H23.1051V11.1878H37.5459V8.31204H20.2175V22.6836ZM2.88905 11.1878H14.4394V19.8078H2.88905V11.1878ZM0 28.4351H2.88905V22.6836H17.327V8.31204H0V28.4351Z" fill="#111111"/>
            <path d="M131.747 19.3209L141.335 15.5154L131.747 11.6791V8.31058L145.063 13.6119V17.479L131.747 22.6894V19.3209Z" fill="#111111"/>
            <path d="M152.741 0C154.808 4.80273 156 10.2918 156 15.5007C156 20.7082 154.808 26.1973 152.741 31L149.39 29.9075C150.642 25.8878 152.146 21.6453 152.146 15.5007C152.146 9.35617 150.642 5.1151 149.39 1.0896L152.741 0Z" fill="#111111"/>
        </svg>
    }
    {(typeof size  != "undefined" && size == 'sm') &&
        <svg width="25" height="31" viewBox="0 0 25 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.747284 19.3209L10.3352 15.5154L0.747284 11.6791V8.31058L14.0626 13.6119V17.479L0.747284 22.6894V19.3209Z" fill="#111111"/>
          <path d="M21.7412 0C23.8081 4.80273 25 10.2918 25 15.5007C25 20.7082 23.8081 26.1973 21.7412 31L18.3895 29.9075C19.6418 25.8878 21.146 21.6453 21.146 15.5007C21.146 9.35617 19.6418 5.1151 18.3895 1.0896L21.7412 0Z" fill="#111111"/>
        </svg>
    }
    </>
  )
}