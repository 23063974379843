import dynamic from "next/dynamic";
import CmsRow from "./CmsRow";

import MiniLogo from "./MiniLogo"

function CmsFooter({ structure }) {
  return (
    <footer id="footer">
      <div className="container xl:max-w-6xl mx-auto">
        <div className="logo">
          <MiniLogo />
        </div>
      </div>
      {structure.footer.map(function (row, i) {
        return (
          <CmsRow row={row} key={i} />
        )
      })}
    </footer>
  );
}

// function CmsFooter({ row }) {
//     return (
//         <section id={`${row.id ?? ''}`}>
//             <div className="container mx-auto">
//                 <div className="logo">
//                     <MiniLogo />
//                 </div>
//             </div>
//             <div className={ row.full ? 'container-xxl mx-auto' : `container mx-auto`}>
//                 <div
//                 className={row.columns != 'full' && row.columns > 1 ? `
//                     grid
//                     2xl:grid-cols-${row.columns}
//                 ` : ''}>
//                     {row.components.map(function (component,i) {
//                         const Component = dynamicComponents[component.name];
//                         component.options.columns = typeof component.options.columns != 'undefined' ? component.options.columns : 1;

//                         return (
//                             <div
//                                 className={`
//                                     col-span-${component.options.columns}
//                                 `}
//                                 key={`component_${i}`}>
//                                     {typeof Component != 'undefined' &&
//                                         <Component component={component} />
//                                     }
//                             </div>
//                         )

//                     })}
//                 </div>
//             </div>
//         </section>
//     )
// }

export default CmsFooter;
